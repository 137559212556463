<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useListTable from '@/comp-functions/useListTable';

export default {
  page: {
    title: "Handover List",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  setup () {
    
    const tableColumns = [
      //this.$t('fields.port.name')
      { key: "hdv_customer", label: "Customer", sortable: true },
      { key: "hdv_date", label: 'Date', sortable: true },
      { key: "hdv_smu_id", label: "Total SMU", sortable: true },
      { key: "hdv_colly", label: "Total Collies", sortable: true }
    //  { key: "smn_expired_date", label: "Expired Date", sortable: true },
    //  { key: "action",label: "#" }
    ]
    
    // Table Handlers
    const columnToBeFilter = [
      'hdv_smu_id',
      'hdv_customer'

    ]

    return {
      tableColumns,
      columnToBeFilter,
      ...useListTable({
        url: 'master/smn'
      }),
    }
  },
  data() {
    return {
      title: "Handover List",
      items: []
    };
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <b-button  variant="light">
              <i class="ri-add-line align-middle mr-2"></i> {{ $t('global.button.add') }}
            </b-button> -->
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select v-model="perPage" size="sm" :options="perPageOptions"></b-form-select>&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="searchQuery"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                no-provider-paging
                no-provider-filtering
                :filter-included-fields="columnToBeFilter"
                :fields="tableColumns"
                :items="itemLists"
                :per-page="perPage"
                :current-page="currentPage"
                :filter="searchQuery"
                responsive
                primary-key="id"
                :sort-by.sync="sortBy"
                show-empty
                :busy="isBusy"
                empty-text="No matching records found"
                :sort-desc.sync="isSortDirDesc"
                class="position-relative"
              >
               <!-- <template v-slot:cell(action)>
                  <a href="input_smu" class="text-primary" v-b-tooltip.hover title="Detail">
                    <i class="ri-file-text-line"></i>
                  </a>
                </template> -->
               <!-- <template #cell(order_id)="data">
                  {{ data.item.ord_id }}
                </template> -->
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalList"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>